<template>
  <review-card
    :key="`list-group-tc-${expanded}`"
    title="Terapias complementares"
  >
    <v-list-group
      v-for="(item, index) in complementaryTherapies"
      :key="index"
      class="item-par"
      :value="expanded"
    >
      <template v-slot:activator>
        <v-list-item-title>
          {{ item.complementarTherapy.name }}
        </v-list-item-title>
      </template>
      <div class="py-3 grey--text text--darken-3">
        <property-value
          property="Comentários aos terapeutas"
          :value="item.therapistComments"
        />
        <property-value
          property="Comentários ao paciente"
          :value="item.patientComments"
        />
      </div>
    </v-list-group>
  </review-card>
</template>

<script>
export default {
  name: "ComplementaryTherapies",
  props: {
    complementaryTherapies: {
      type: Array,
      default: () => [],
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
